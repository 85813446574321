import React from "react"
import { EventCard, Layout, SEO } from "../../../components"
import { graphql } from "gatsby"
import { titleWithoutPipe } from "../../../utils/dom"
import { SUB_NAV_SOCIAL_EVENTS_LINKS } from "../../../utils/links"

export default function BarmitzvaEventsPage({ data }) {
  const events = data.allShopifyCollection.edges
  return (
    <Layout showSubMenu={true} currentLink="Sociales" links={SUB_NAV_SOCIAL_EVENTS_LINKS}>
      <SEO title={`Eventos Sociales - Compromiso`} />
      <section className="products">
        {events.map((ev, idx) => (
          <EventCard
            key={idx}
            title={titleWithoutPipe(ev.node.title)}
            image={ev.node.image}
            link={`sociales/${ev.node.handle}`}
          />
        ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GET_ENGAGEMENT_EVENTS {
    allShopifyCollection(filter: { title: { regex: "/SOCIALES-COMPROMISO/" } }) {
      edges {
        node {
          title
          description
          handle
          image {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 920) {
                  originalName
                  srcSetWebp
                  tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
